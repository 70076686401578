<template>
  <div>
    <b-row class="match-height">
      <b-col sm="6" md="4" lg="3" xl="2">
        <simple-card bgVariant="primary" textVariant="white" :statistic="formatNumber(summary.gross || 0, 2, currencySymbol)"
          :statisticDescription="$t('components.cards.meli_reports.summary.sales_amount')" />
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="2">
        <simple-card bgVariant="danger" textVariant="white" :statistic="formatNumber(summary.fee || 0, 2, currencySymbol)"
          :statisticDescription="$t('components.cards.meli_reports.summary.fee')" />
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="2">
        <simple-card bgVariant="danger" textVariant="white" :statistic="formatNumber(summary.shippingCost || 0, 2, currencySymbol)"
          :statisticDescription="$t('components.cards.meli_reports.summary.shipping_cost')" />
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="2">
        <simple-card bgVariant="success" textVariant="white" :statistic="formatNumber(summary.net || 0 || 0, 2, currencySymbol)"
          :statisticDescription="$t('components.cards.meli_reports.summary.net_value')" />
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="2">
        <simple-card bgVariant="secondary" textVariant="white" :statistic="formatNumber(summary.qty || 0, 0, '', unitSymbol)"
          :statisticDescription="$t('components.cards.meli_reports.summary.sold_items')" />
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="2">
        <simple-card bgVariant="secondary" textVariant="white" :statistic="formatNumber(summary.averageGrossTicket || 0, 2, currencySymbol)"
          :statisticDescription="$t('components.cards.meli_reports.summary.average_gross_ticket')" />
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="2">
        <simple-card bgVariant="info" textVariant="white" :statistic="formatNumber(summary.feePercentage || 0, 2, '', percentageSymbol)"
          :statisticDescription="$t('components.cards.meli_reports.summary.fee_percentage')" />
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="2">
        <simple-card bgVariant="danger" textVariant="white" :statistic="formatNumber(summary.averageFee || 0, 2, currencySymbol)"
          :statisticDescription="$t('components.cards.meli_reports.summary.average_fee')" />
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="2">
        <simple-card bgVariant="danger" textVariant="white" :statistic="formatNumber(summary.totalAverageShipping || 0, 2, currencySymbol)"
          :statisticDescription="$t('components.cards.meli_reports.summary.total_average_shipping')" />
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="2">
        <simple-card bgVariant="danger" textVariant="white" :statistic="formatNumber(summary.chargedAverageShipping, 2, currencySymbol)"
          :statisticDescription="$t('components.cards.meli_reports.summary.charged_average_shipping')" />
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="2">
        <simple-card bgVariant="secondary" textVariant="white" :statistic="formatNumber(summary.qtyChargedShipping || 0, 0, '', unitSymbol)"
          :statisticDescription="$t('components.cards.meli_reports.summary.qty_charged_shipping')" />
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="2">
        <simple-card bgVariant="secondary" textVariant="white"
          :statistic="formatNumber(summary.averageNetTicket || 0, 2, currencySymbol)"
          :statisticDescription="$t('components.cards.meli_reports.summary.average_net_ticket')" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import SimpleCard from '@core-md/components/cards/SimpleCard.vue'
import stringHelper from '@core-md/mixins/stringHelper'

export default {
  components: {
    BRow,
    BCol,
    SimpleCard,
  },
  props: {
    summary: {
      type: Object,
    },
    showExtra: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    stringHelper,
  ],
  data() {
    return {
      currencySymbol: 'R$ ',
      percentageSymbol: ' %',
      unitSymbol: ' Un.',
    }
  },
}
</script>
